import { WebAuth } from 'auth0-js';
import EventEmitter from 'events';
import { auth0Config } from '@/config';

const localStorageKey = 'AirsageJacquerieAdmin';
const loginEvent = 'login';

class AuthService extends EventEmitter {
  idToken = null;

  profile = null;

  tokenExpiry = null;

  accessToken = null;

  accessTokenExpiry = null;

  connection = null;

  constructor({
    domain,
    redirectUri = `${window.location.origin}/callback`,
    audience,
    clientID,
    connection,
    responseType = 'token id_token',
    scope = 'openid profile email'
  } = {}) {
    super();
    this.webAuth = new WebAuth({
      domain,
      redirectUri,
      clientID,
      audience,
      responseType,
      scope
    });
    this.connection = connection;
  }

  // Starts the user login flow
  login(appState) {
    this.webAuth.authorize({ appState });
  }

  // Handles the callback request from Auth0
  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.webAuth.parseHash((error, authResult) => {
        if (error) {
          reject(error);
        } else if (authResult) {
          this.localLogin(authResult);
          resolve(authResult.idToken);
        }
      });
    });
  }

  localLogin(authResult) {
    if (!(authResult.idTokenPayload['http://airsage.com/roles'] || []).includes('Jacquerie Admin')) {
      this.emit(loginEvent, {
        loggedIn: false,
        profile: {},
        state: {}
      });
    } else {
      this.idToken = authResult.idToken;
      this.profile = authResult.idTokenPayload;

      this.tokenExpiry = this.profile.exp * 1000;

      this.accessToken = authResult.accessToken;
      this.accessTokenExpiry = Date.now() + (authResult.expiresIn * 1000);

      localStorage.setItem(localStorageKey, this.tokenExpiry);

      this.emit(loginEvent, {
        loggedIn: true,
        profile: authResult.idTokenPayload,
        state: authResult.state || {}
      });
    }
  }

  renewTokens() {
    return new Promise((resolve, reject) => {
      this.webAuth.checkSession({}, (error, authResult) => {
        if (error) {
          reject(error);
        } else {
          this.localLogin(authResult);
          resolve(authResult);
        }
      });
    });
  }

  changePassword(email) {
    return new Promise((resolve, reject) => {
      this.webAuth.changePassword({
        connection: this.connection,
        email
      }, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  logOut() {
    localStorage.removeItem(localStorageKey);

    this.idToken = null;
    this.tokenExpiry = null;
    this.profile = null;

    const { clientID } = auth0Config;
    this.webAuth.logout({
      clientID,
      returnTo: `${window.location.origin}/logout`
    });
  }

  isAuthenticated() {
    const expiry = this.tokenExpiry || localStorage.getItem(localStorageKey);
    return expiry !== null && Date.now() < expiry;
  }

  isAccessTokenValid() {
    return this.accessToken && this.accessTokenExpiry && Date.now() < this.accessTokenExpiry;
  }

  getAccessToken() {
    return new Promise((resolve, reject) => {
      if (this.isAccessTokenValid()) {
        resolve(this.accessToken);
      } else {
        this.renewTokens().then((authResult) => {
          resolve(authResult.accessToken);
        });
      }
    });
  }
}

export default AuthService;
