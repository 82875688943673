import axios from 'axios';
import Service from '@/services/service';

export default class OrganizationService extends Service {
  constructor(token) {
    super('organizations', token);
    this.CancelToken = axios.CancelToken;
    this.isCancel = axios.isCancel;
  }

  setCredits(id, transaction, options) {
    return this.service.patch(`/${id}/credits`, transaction, options);
  }

  listUsers(id, options) {
    return this.service.get(`/${id}/users`, options);
  }

  listOrders(id, options) {
    return this.service.get(`/${id}/orders`, options);
  }

  listFeatures(id, options) {
    return this.service.get(`/${id}/pois`, options);
  }

  listTransactions(id, options) {
    return this.service.get(`/${id}/transactions`, options);
  }

  getAPIKey(id, options) {
    return this.service.get(`/${id}/api-key`, options);
  }

  issueAPIKey(id, options) {
    return this.service.post(`/${id}/api-key`, options);
  }

  search(options) {
    return this.service.get('search', options);
  }
}
