<template>
  <v-container>
    <v-row>
      <v-select label="Allowed Countries" v-model="value.countries" :items="countries" multiple />
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        countries: ['us']
      })
    }
  },

  data() {
    return {
      countries: [{
        text: 'Unites States of America',
        value: 'us',
        disabled: false
      }, {
        text: 'Canada',
        value: 'ca',
        disabled: false
      }, {
        text: 'Israel',
        value: 'is',
        disabled: true
      }]
    };
  }
};
</script>
