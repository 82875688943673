<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card outlined :loading="loading">
          <v-card-title>
            <div class="text-h5">User Details</div>
            <v-spacer />
            <v-btn color="error" depressed :loading="blocking" @click="unblockUser" v-if="blocked">Unblock</v-btn>
            <v-btn color="error" depressed outlined :loading="blocking" @click="blockUser" v-else>Block</v-btn>
          </v-card-title>
          <v-card-text>
            <v-form v-model="formValid">
              <v-text-field label="Name" v-model="user.name" :rules="rules.name" />
              <v-text-field label="Email" v-model="user.email" :rules="rules.email" disabled />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-chip class="mr-2" outlined label :color="user.emailVerified ? 'success' : 'error'">
              <span>Email Verified:</span>
              <v-icon right v-if="user.emailVerified">mdi-check</v-icon>
              <v-icon right v-else>mdi-close</v-icon>
            </v-chip>
            <v-chip class="mr-2" outlined label>
              <span>Logins: {{ user.numLogins }}</span>
            </v-chip>
            <v-chip class="mr-2" outlined label>
              <span>Last Login: {{ user.lastLogin | formatDate }}</span>
            </v-chip>
            <v-chip class="mr-2" outlined label>
              <span>Last Password Reset: {{ user.lastPasswordReset | formatDate }}</span>
            </v-chip>
            <v-spacer />
            <v-btn color="primary darken-1" text @click="save" :loading="pending" :disabled="errors">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-card-title>
            <div class="text-h5">Change Password</div>
          </v-card-title>
          <v-card-text>
            <v-form ref="passwordForm" v-model="password.formValid">
              <v-text-field label="Password" v-model="password.password" :rules="rules.password" type="password" />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary darken-1" text @click="savePassword" :loading="password.pending" :disabled="!password.formValid">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined :loading="loadingOrganizations">
          <v-data-table :headers="headers" :items="organizations" hide-default-footer>
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title class="text-h5">Organizations</v-toolbar-title>
                <v-spacer />
                <v-dialog v-model="invite.dialog" max-width="500px" persistent>
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" depressed v-on="on">Add To Organization</v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add User To Organization</span>
                    </v-card-title>
                    <v-card-text>
                      <v-form v-model="invite.formValid">
                        <v-container>
                          <v-row wrap>
                            <v-col>
                              <v-autocomplete label="Organization" v-model="invite.organization" :rules="rules.organization" :loading="invite.searching" :items="invite.items" :search-input.sync="invite.search" item-text="name" return-object cache-items>
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-title>Type to Search Organizations</v-list-item-title>
                                  </v-list-item>
                                </template>
                              </v-autocomplete>
                            </v-col>
                            <v-col>
                              <v-select label="Role" v-model="invite.role" :rules="rules.role" :items="roles"/>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="error" text @click="cancelInvite">Cancel</v-btn>
                      <v-spacer />
                      <v-btn color="primary" text @click="addToOrganization" :disabled="!invite.formValid">Add</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:items="props">
              <td>{{ props.item.name }}</td>
              <td class="text-capitalize">{{ props.item.role }}</td>
            </template>
            <template v-slot:item.action="{ item }">
              <div @click.stop="">
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-btn class="user-menu" icon v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="changeRole(item, 'owner')" v-show="item.role !== 'owner'">
                      <v-list-item-title>Make Owner</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="changeRole(item, 'lead')" v-show="item.role !== 'lead'">
                      <v-list-item-title>Make Lead</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="changeRole(item, 'member')" v-show="item.role !== 'member'">
                      <v-list-item-title>Make Member</v-list-item-title>
                    </v-list-item>
                    <v-divider />
                    <v-list-item @click="removeFromOrganization(item)">
                      <v-list-item-title>Remove</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import get from 'lodash/get';
import alert from '@/mixins/alert';
import formValidation, { name, email, organization, password, role } from '@/mixins/validation';

export default {
  mixins: [alert, formValidation, name, email, organization, password, role],

  data() {
    return {
      user: {},
      organizations: [],
      pending: false,
      isMounted: false,
      loading: false,
      loadingOrganizations: false,
      blocking: false,
      headers: [{
        text: 'Name',
        align: 'left',
        sortable: false,
        value: 'name'
      }, {
        text: 'Role',
        align: 'left',
        sortable: false,
        value: 'role'
      }, {
        text: '',
        value: 'action',
        sortable: false
      }],
      roles: [
        'Owner',
        'Lead',
        'Member'
      ],
      invite: {
        dialog: false,
        organization: null,
        role: null,
        search: null,
        searching: false,
        items: [],
        formValid: false
      },
      password: {
        password: '',
        formValid: false,
        pending: false
      }
    };
  },

  mounted() {
    this.isMounted = true;
  },

  computed: {
    blocked() {
      return get(this.user, 'blocked', false);
    },

    canSave() {
      return this.isMounted && !this.errors;
    }
  },

  async beforeRouteEnter(to, from, next) {
    next(async vm => await vm.loadUser(to.params.id));
  },

  async beforeRouteUpdate(to, from, next) {
    this.user = {};
    this.organizations = [];
    await this.loadUser(to.params.id);
    next();
  },

  watch: {
    // eslint-disable-next-line
    'invite.search': function (value) {
      if (value) {
        this.searchOrganizations(value);
      }
    }
  },

  methods: {
    async loadUser(id) {
      try {
        this.loading = true;
        const { data } = await this.$services.user.read(id);
        this.user = data;
        await this.loadUserOrganizations();
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loading = false;
      }
    },

    async loadUserOrganizations() {
      try {
        this.loadingOrganizations = true;
        const { data } = await this.$services.user.listOrganizations(this.user.id);
        this.organizations = data;
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loadingOrganizations = false;
      }
    },

    async save() {
      if (this.canSave) {
        try {
          this.pending = true;
          const { id: uid, email, name } = this.user;
          await this.$services.user.update(uid, { name, email });
          this.alertSuccess(`${this.user.name} updated`);
        } catch (error) {
          this.alertError(error);
        } finally {
          this.pending = false;
          delete this.user.password;
        }
      }
    },

    async changeRole(organization, role) {
      try {
        const { id: oid, name } = organization;
        this.loadingOrganizations = true;
        await this.$services.user.updateRoleInOrganization(this.user.id, oid, role, { headers: { oid } });
        this.loadUserOrganizations();
        this.alertSuccess(`${this.user.name} is now a${role === 'owner' ? 'n' : ''} ${role} of ${name}`);
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loadingOrganizations = false;
      }
    },

    async addToOrganization() {
      try {
        const { organization: { id: oid, name }, role } = this.invite;
        this.loadingOrganizations = true;
        await this.$services.user.addToOrganization(this.user.id, oid, role.toLowerCase(), { headers: { oid } });
        this.loadUserOrganizations();
        this.alertSuccess(`${this.user.name} is now a${role === 'owner' ? 'n' : ''} ${role} of ${name}`);
      } catch (error) {
        this.alertError(error);
      } finally {
        this.cancelInvite();
        this.loadingOrganizations = false;
      }
    },

    async removeFromOrganization(organization) {
      try {
        const { id, name } = organization;
        this.loadingOrganizations = true;
        await this.$services.user.removeFromOrganization(this.user.id, id);
        this.loadUserOrganizations();
        this.alertSuccess(`${this.user.name} has been removed from ${name}`);
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loadingOrganizations = false;
      }
    },

    async searchOrganizations(term) {
      const { CancelToken } = this.$services.organization;
      try {
        this.invite.searching = true;
        if (this.cancel) {
          this.cancel();
        }
        const { data } = await this.$services.organization.search({
          params: { term },
          cancelToken: new CancelToken(c => this.cancel = c)
        });
        this.invite.items = data;
      } catch (error) {
        if (this.$services.organization.isCancel(error)) {
          this.cancel = null;
        } else {
          this.alertError(error);
        }
      } finally {
        this.invite.searching = false;
      }
    },

    cancelInvite() {
      this.invite = {
        dialog: false,
        organization: null,
        search: null,
        searching: false,
        items: [],
        formValid: false
      };
    },

    async blockUser() {
      try {
        const { id: uid, name } = this.user;
        this.blocking = true;
        await this.$services.user.block(uid);
        this.user.blocked = true;
        this.alertSuccess(`${name} has been blocked`);
      } catch (error) {
        this.alertError(error);
      } finally {
        this.blocking = false;
      }
    },

    async unblockUser() {
      try {
        const { id: uid, name } = this.user;
        this.blocking = true;
        await this.$services.user.unblock(uid);
        this.user.blocked = false;
        this.alertSuccess(`${name} has been unblocked`);
      } catch (error) {
        this.alertError(error);
      } finally {
        this.blocking = false;
      }
    },

    async savePassword() {
      try {
        this.password.pending = true;
        const { id: uid, email } = this.user;
        const { password } = this.password;
        await this.$services.user.update(uid, { email, password });
        this.alertSuccess('Password updated');
      } catch (error) {
        this.alertError(error);
      } finally {
        this.password.pending = false;
        this.$refs.passwordForm.reset();
      }
    }
  },

  filters: {
    formatDate(date) {
      if (date) {
        return format(parseISO(date), 'MMM d, yy @ h:mma');
      }
      return '?';
    }
  }
};
</script>
