<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card outlined :loading="loading">
          <v-card-title class="text-h5">Organization Details</v-card-title>
          <v-card-text>
            <basic-organization-config ref="basicForm" v-model="organization" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary darken-1" text @click="save" :loading="pending" :disabled="!canSave">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined :loading="loading">
          <v-card-title class="text-h5">Product Access</v-card-title>
          <organization-product-access ref="productAccessForm" v-model="organization.products"
            :trial="organization.trial" />
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary darken-1" text @click="saveProductAccess" :loading="productAccessPending"
              :disabled="!canSaveProductAccess">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined :loading="loadingTransactions">
          <v-card-title>
            <div class="text-h5">Credits</div>
            <v-spacer />
            <v-dialog v-model="transactionsDialog" max-width="900px" persistent>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" outlined v-on="on">
                  <v-icon left>mdi-format-list-bulleted</v-icon>
                  <span>Transactions</span>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Transactions</span>
                  <v-spacer />
                  <v-btn icon @click="transactionsDialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-data-table :headers="transactionHeaders" :items="transactions" multi-sort>
                  <template v-slot:item.date="{ item }">
                    <span>{{ item.date | formatDate }}</span>
                  </template>
                  <template v-slot:item.amount="{ item }">
                    <span>{{ item.amount }}</span>
                  </template>
                  <template v-slot:item.description="{ item }">
                    <span>{{ item.description }}</span>
                  </template>
                </v-data-table>
              </v-card>
            </v-dialog>
          </v-card-title>
          <v-card-text>
            <organization-credits ref="creditsForm" v-model="organization" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary darken-1" text @click="saveCredits" :loading="creditsPending"
              :disabled="!canSaveCredits">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <organization-api-key />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined :loading="loadingMembers">
          <v-data-table :headers="headers" :items="members" hide-default-footer>
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title class="text-h5">Members</v-toolbar-title>
                <v-spacer />
                <v-dialog v-model="inviteDialog" max-width="500px" persistent>
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" depressed v-on="on">Add Member</v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add Member</span>
                    </v-card-title>
                    <v-card-text>
                      <v-combobox label="User" v-model="invitee.user" :rules="rules.user" :loading="searching"
                        :items="users" :search-input.sync="userSearch" item-text="name" item-value="email" return-object
                        cache-items clearable>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>Type to Search Users</v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-combobox>
                      <v-select label="Role" v-model="invitee.role" :rules="rules.role" :items="roles" />
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="error" text @click="cancelInvite">Cancel</v-btn>
                      <v-spacer />
                      <v-btn color="primary" text @click="addToOrganization" :loading="sendingInvite"
                        :disabled="invitee.formValid">Add</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.name="{ item }">
              <span>{{ item.name }}</span>
            </template>
            <template v-slot:item.email="{ item }">
              <span>{{ item.email }}</span>
            </template>
            <template v-slot:item.role="{ item }">
              <span class="text-capitalize">{{ item.role }}</span>
            </template>
            <template v-slot:item.action="{ item }">
              <div @click.stop="">
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-btn class="user-menu" icon v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="changeRole(item, 'owner')" v-show="item.role !== 'owner'">
                      <v-list-item-title>Make Owner</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="changeRole(item, 'lead')" v-show="item.role !== 'lead'">
                      <v-list-item-title>Make Lead</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="changeRole(item, 'member')" v-show="item.role !== 'member'">
                      <v-list-item-title>Make Member</v-list-item-title>
                    </v-list-item>
                    <v-divider />
                    <v-list-item @click="removeMember(item)">
                      <v-list-item-title>Remove</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined :loading="loadingOrders">
          <v-data-table :headers="orderHeaders" :items="orders" multi-sort>
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title class="text-h5">Studies</v-toolbar-title>
              </v-toolbar>
            </template>
            <template v-slot:item.name="{ item }">
              <v-icon class="mr-2" :color="getAvatarColor(item.product)">{{ getAvatarIcon(item.product) }}</v-icon>
              <span>{{ item.name }}</span>
            </template>
            <template v-slot:item.status="{ item }">
              <v-icon :color="getStatusColor(item.status)">{{ getStatusIcon(item.status) }}</v-icon>
            </template>
            <template v-slot:item.created="{ item }">
              <span>{{ item.created | formatDate }}</span>
            </template>
            <template v-slot:item.product="{ item }">
              <span>{{ item.product | formatProduct }}</span>
            </template>
            <template v-slot:item.credits="{ item }">
              <span>{{ item.credits }}</span>
            </template>
            <template v-slot:item.cost="{ item }">
              <span>{{ item.cost | formatCost }}</span>
            </template>
            <template v-slot:item.action="{ item }">
              <div @click.stop>
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-btn class="user-menu" icon v-on="on" v-show="item.status !== 'DELETING'">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-dialog v-model="rename.dialog" width="500">
                      <template v-slot:activator="{ on }">
                        <v-list-item v-on="on">
                          <v-list-item-title>Rename</v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-card>
                        <v-card-title primary-title>Rename Order</v-card-title>
                        <v-card-text>
                          <v-text-field label="Name" :placeholder="item.name" v-model="rename.newName" />
                        </v-card-text>
                        <v-card-actions>
                          <v-btn color="error" text @click="rename.dialog = false">Cancel</v-btn>
                          <v-spacer />
                          <v-btn color="primary" depressed :loading="rename.pending" @click="renameOrder(item)">Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="rerun.dialog" width="500">
                      <template v-slot:activator="{ on }">
                        <v-list-item v-on="on" v-show="['SUCCEEDED', 'FAILED', 'ARCHIVED', 'CANCELED', 'PENDING'].includes(item.status)">
                          <v-list-item-title>Rerun</v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-card>
                        <v-card-title primary-title>Rerun Order</v-card-title>
                        <v-card-text>Are you sure you want to rerun {{ item.name }}?</v-card-text>
                        <v-card-actions>
                          <v-btn color="error" text @click="rerun.dialog = false">Cancel</v-btn>
                          <v-spacer />
                          <v-btn color="primary" depressed :loading="rerun.pending" @click="rerunOrder(item)">Rerun
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="release.dialog" width="500">
                      <template v-slot:activator="{ on }">
                        <v-list-item v-on="on" v-show="item.status === 'QUEUED'">
                          <v-list-item-title>Release</v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-card>
                        <v-card-title primary-title>Release Order</v-card-title>
                        <v-card-text>Are you sure you want to release {{ item.name }}?</v-card-text>
                        <v-card-actions>
                          <v-btn color="error" text @click="release.dialog = false">Cancel</v-btn>
                          <v-spacer />
                          <v-btn color="primary" depressed :loading="release.pending" @click="releaseOrder(item)">Release</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="archive.dialog" width="500">
                      <template v-slot:activator="{ on }">
                        <v-list-item v-on="on" v-show="item.status === 'SUCCEEDED'">
                          <v-list-item-title>Archive</v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-card>
                        <v-card-title primary-title>Archive Order</v-card-title>
                        <v-card-text>Are you sure you want to archive {{ item.name }}?</v-card-text>
                        <v-card-actions>
                          <v-btn color="error" text @click="archive.dialog = false">Cancel</v-btn>
                          <v-spacer />
                          <v-btn color="primary" depressed :loading="archive.pending" @click="archiveOrder(item)">Archive
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="unarchive.dialog" width="500">
                      <template v-slot:activator="{ on }">
                        <v-list-item v-on="on" v-show="item.status === 'ARCHIVED'">
                          <v-list-item-title>Unarchive</v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-card>
                        <v-card-title primary-title>Unarchive Order</v-card-title>
                        <v-card-text>Are you sure you want to unarchive {{ item.name }}?</v-card-text>
                        <v-card-actions>
                          <v-btn color="error" text @click="unarchive.dialog = false">Cancel</v-btn>
                          <v-spacer />
                          <v-btn color="primary" depressed :loading="unarchive.pending" @click="unarchiveOrder(item)">Unarchive
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-divider v-show="['SUCCEEDED', 'FAILED', 'ARCHIVED', 'CANCELED'].includes(item.status)" />
                    <v-dialog v-model="removeOrderDialog.dialog" width="500">
                      <template v-slot:activator="{ on }">
                        <v-list-item v-on="on" v-show="['SUCCEEDED', 'FAILED', 'ARCHIVED', 'CANCELED'].includes(item.status)">
                          <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-card>
                        <v-card-title primary-title>Delete Order</v-card-title>
                        <v-card-text>Are you sure you want to delete {{ item.name }}?</v-card-text>
                        <v-card-actions>
                          <v-btn color="error" text @click="removeOrderDialog.dialog = false">Cancel</v-btn>
                          <v-spacer />
                          <v-btn color="primary" depressed :loading="removeOrderDialog.pending"
                            @click="removeOrder(item)">Delete</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined :loading="loadingFeatures">
          <v-data-table :headers="featureHeaders" :items="features" multi-sort>
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title class="text-h5">Features</v-toolbar-title>
              </v-toolbar>
            </template>
            <template v-slot:item.name="{ item }">
              <span>{{ item.name }}</span>
            </template>
            <template v-slot:item.created="{ item }">
              <span>{{ item.created | formatDate }}</span>
            </template>
            <template v-slot:item.purpose="{ item }">
              <span>{{ item.purpose | formatPurpose }}</span>
            </template>
            <template v-slot:item.numStudies="{ item }">
              <span>{{ item.numStudies }}</span>
            </template>
            <template v-slot:item.action="{ item }">
              <div @click.stop="">
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-btn class="user-menu" icon v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-dialog v-model="removeFeatureDialog.dialog" width="500">
                      <template v-slot:activator="{ on }">
                        <v-list-item v-on="on">
                          <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-card>
                        <v-card-title primary-title>Delete {{ item.purpose | formatPurpose }}</v-card-title>
                        <v-card-text>
                          <p>Are you sure you want to delete {{ item.name }}?</p>
                          <v-alert type="warning" border="left" dense outlined text v-if="item.purpose === 'studyarea'">
                            This Feature is a Study Area. Deleting it will delete studies that use it.</v-alert>
                          <v-alert type="warning" border="left" dense outlined text v-if="item.onlyPoiIn.length > 0">
                            This Feature is the only POI in {{ item.onlyPoiIn.length }} studies. Those studies will be
                            deleted along with this POI.</v-alert>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn color="error" text @click="removeFeatureDialog.dialog = false">Cancel</v-btn>
                          <v-spacer />
                          <v-btn color="primary" depressed :loading="removeFeatureDialog.pending"
                            @click="removeFeature(item)">Delete</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="delete-org-card" outlined>
          <v-card-title class="error white--text text-h5">Delete Organization</v-card-title>
          <v-card-text>
            <p class="my-4">To confirm deletion, type permanently delete in the text input field.</p>
            <v-text-field color="error" dense outlined hide-details placeholder="permanently delete"
              v-model="deleteText" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="error" depressed outlined :loading="deleting" :disabled="!canDelete" @click="deleteOrg">Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import format from 'date-fns/format';
import alert from '@/mixins/alert';
import { user, role } from '@/mixins/validation';
import studyIcon from '@/mixins/studyIcon';
import BasicOrganizationConfig from '@/components/BasicOrganizationConfig';
import OrganizationProductAccess from '@/components/OrganizationProductAccess';
import OrganizationCredits from '@/components/OrganizationCredits';
import OrganizationApiKey from '@/components/OrganizationApiKey';

const AT_REST_STATUSES = ['SUCCEEDED', 'FAILED', 'PENDING', 'ARCHIVED'];
const sleep = (wait = 1) => new Promise(r => setTimeout(r, wait * 1000));
let orderRefreshTimeoutHandler;

export default {
  mixins: [alert, user, role, studyIcon],

  components: {
    BasicOrganizationConfig,
    OrganizationProductAccess,
    OrganizationCredits,
    OrganizationApiKey
  },

  data() {
    return {
      organization: {},
      members: [],
      orders: [],
      features: [],
      transactions: [],
      rename: {
        dialog: false,
        pending: false,
        newName: ''
      },
      rerun: {
        dialog: false,
        pending: false
      },
      release: {
        dialog: false,
        pending: false
      },
      archive: {
        dialog: false,
        pending: false
      },
      unarchive: {
        dialog: false,
        pending: false
      },
      removeOrderDialog: {
        dialog: false,
        pending: false
      },
      removeFeatureDialog: {
        dialog: false,
        pending: false
      },
      pending: false,
      creditsPending: false,
      productAccessPending: false,
      isMounted: false,
      loading: false,
      searching: false,
      loadingMembers: false,
      loadingOrders: false,
      loadingFeatures: false,
      loadingTransactions: false,
      inviteDialog: false,
      sendingInvite: false,
      transactionsDialog: false,
      headers: [{
        text: 'Name',
        align: 'left',
        sortable: false,
        value: 'name'
      }, {
        text: 'Role',
        align: 'left',
        sortable: false,
        value: 'role'
      }, {
        text: '',
        value: 'action',
        sortable: false
      }],
      roles: [
        'Owner',
        'Lead',
        'Member'
      ],
      userSearch: '',
      users: [],
      invitee: {
        user: null,
        role: null,
        formValid: false
      },
      orderHeaders: [{
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'name'
      }, {
        text: 'Status',
        align: 'left',
        sortable: true,
        value: 'status'
      }, {
        text: 'Created',
        align: 'left',
        sortable: true,
        value: 'created'
      }, {
        text: 'Product',
        align: 'left',
        sortable: true,
        value: 'product'
      }, {
        text: 'Credits Spent',
        align: 'left',
        sortable: true,
        value: 'credits'
      }, {
        text: 'Cost to Run',
        align: 'left',
        sortable: true,
        value: 'cost'
      }, {
        text: '',
        value: 'action',
        sortable: false
      }],
      featureHeaders: [{
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'name'
      }, {
        text: 'Created',
        align: 'left',
        sortable: true,
        value: 'created'
      }, {
        text: 'Purpose',
        align: 'left',
        sortable: true,
        value: 'purpose'
      }, {
        text: '# Studies',
        align: 'left',
        sortable: true,
        value: 'numStudies'
      }, {
        text: '',
        value: 'action',
        sortable: false
      }],
      transactionHeaders: [{
        text: 'Date',
        align: 'left',
        sortable: true,
        value: 'date'
      }, {
        text: 'Amount',
        align: 'right',
        sortable: true,
        value: 'amount'
      }, {
        text: 'Note',
        align: 'left',
        sortable: false,
        value: 'description'
      }],
      deleteText: '',
      deleting: false
    };
  },

  mounted() {
    this.isMounted = true;
  },

  computed: {
    canSave: {
      cache: false,
      get() {
        return this.isMounted && !this.$refs.basicForm.errors;
      }
    },

    canSaveCredits: {
      cache: false,
      get() {
        return this.isMounted && !this.$refs.creditsForm.errors;
      }
    },

    canSaveProductAccess: {
      cache: false,
      get() {
        return this.isMounted && !this.$refs.productAccessForm.errors;
      }
    },

    canDelete() {
      return this.deleteText.toLowerCase() === 'permanently delete';
    }
  },

  async beforeRouteEnter(to, from, next) {
    next(async vm => await vm.loadOrganization(to.params.id));
  },

  async beforeRouteUpdate(to, from, next) {
    this.organization = {};
    await this.loadOrganization(to.params.id);
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (orderRefreshTimeoutHandler) {
      clearTimeout(orderRefreshTimeoutHandler);
    }
    next();
  },

  watch: {
    userSearch(value) {
      if (value) {
        this.searchUsers(value);
      }
    }
  },

  methods: {
    async loadOrganization(id) {
      try {
        this.loading = true;
        const { data } = await this.$services.organization.read(id);
        this.organization = data;
        await Promise.all([
          this.loadOrganizationMembers(),
          this.loadOrganizationOrders(),
          this.loadOrganizationFeatures(),
          this.loadOrganizationTransactions()
        ]);
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loading = false;
      }
    },

    async loadOrganizationMembers() {
      try {
        this.loadingMembers = true;
        const { data } = await this.$services.organization.listUsers(this.organization.id);
        this.members = data;
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loadingMembers = false;
      }
    },

    async loadOrganizationOrders() {
      try {
        this.loadingOrders = true;
        const { data } = await this.$services.organization.listOrders(this.organization.id, { params: { summary: true } });
        this.orders = data;
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loadingOrders = false;
        if (this.orders.some(o => !AT_REST_STATUSES.includes(o.status))) {
          if (orderRefreshTimeoutHandler) {
            clearTimeout(orderRefreshTimeoutHandler);
          }
          orderRefreshTimeoutHandler = setTimeout(this.loadOrganizationOrders, 5000);
        }
      }
    },

    async loadOrganizationFeatures() {
      try {
        this.loadingFeatures = true;
        const { data } = await this.$services.organization.listFeatures(this.organization.id, { params: { includeStudyAreas: true } });
        this.features = data;
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loadingFeatures = false;
      }
    },

    async loadOrganizationTransactions() {
      try {
        this.loadingTransactions = true;
        const { data } = await this.$services.organization.listTransactions(this.organization.id);
        this.transactions = data;
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loadingTransactions = false;
      }
    },

    async save() {
      if (this.canSave) {
        try {
          this.pending = true;
          const { id, name, email, trial, useLegacyData } = this.organization;
          let { credits, products } = this.organization;
          if (trial) {
            credits = 0;
            products = {
              dla: {
                read: true,
                write: true,
                freeze: false,
                allowedMonths: { months: [], negate: false }
              },
              tla: {
                read: false,
                write: false,
                freeze: false,
                allowedMonths: { months: [], negate: false }
              },
              ad: {
                read: false,
                write: false,
                freeze: false,
                allowedMonths: { months: [], negate: false }
              },
              nwtm: {
                read: false,
                write: false,
                freeze: false,
                allowedMonths: { months: [], negate: false }
              },
              pp: {
                read: false,
                write: false,
                freeze: false,
                allowedMonths: { months: [], negate: false }
              },
              ctm: {
                read: false,
                write: false,
                freeze: false,
                allowedMonths: { months: [], negate: false }
              }
            };
            this.organization.credits = credits;
            this.organization.products = products;
          }
          await this.$services.organization.update(id, { name, email, trial, useLegacyData, credits, products });
          this.alertSuccess(`${this.organization.name} updated`);
        } catch (error) {
          this.alertError(error);
        } finally {
          this.pending = false;
        }
      }
    },

    async saveCredits() {
      if (this.canSaveCredits) {
        try {
          this.creditsPending = true;
          let { id, credits } = this.organization;
          credits = Number(credits);
          const description = this.$refs.creditsForm.description;
          await this.$services.organization.setCredits(id, { credits, description });
          await this.loadOrganizationTransactions(this.organization.id);
          this.alertSuccess(`${this.organization.name} updated`);
        } catch (error) {
          this.alertError(error);
        } finally {
          this.creditsPending = false;
          this.$refs.creditsForm.reset();
        }
      }
    },

    async saveProductAccess() {
      if (this.canSaveProductAccess) {
        try {
          this.productAccessPending = true;
          let { id, products } = this.organization;
          await this.$services.organization.update(id, { products });
          this.alertSuccess(`${this.organization.name} updated`);
        } catch (error) {
          this.alertError(error);
        } finally {
          this.productAccessPending = false;
        }
      }
    },

    async searchUsers(term) {
      const { CancelToken } = this.$services.user;
      try {
        this.searching = true;
        if (this.cancel) {
          this.cancel();
        }
        const { data } = await this.$services.user.search({
          params: { term },
          cancelToken: new CancelToken(c => this.cancel = c)
        });

        this.users = data;
      } catch (error) {
        if (this.$services.user.isCancel(error)) {
          this.cancel = null;
        } else {
          this.alertError(error);
        }
      } finally {
        this.searching = false;
      }
    },

    cancelInvite() {
      this.inviteDialog = false;
      this.userSearch = '';
      this.invitee = {
        user: null,
        role: null,
        formValid: false
      };
    },

    async addToOrganization() {
      try {
        const { user, role } = this.invitee;
        this.sendingInvite = true;
        await this.$services.user.addToOrganization(user.id, this.organization.id, role.toLowerCase());
        this.alertSuccess('User Added');
      } catch (error) {
        this.alertError(error);
      } finally {
        this.cancelInvite();
        this.sendingInvite = false;
        this.loadOrganizationMembers();
      }
    },

    async changeRole(member, role) {
      try {
        const { id, name } = member;
        await this.$services.user.updateRoleInOrganization(id, this.organization.id, role);
        this.loadOrganizationMembers();
        this.alertSuccess(`${name} is now a${role === 'owner' ? 'n' : ''} ${role}`);
      } catch (error) {
        this.alertError(error);
      }
    },

    async removeMember(member) {
      try {
        const { id, name } = member;
        await this.$services.user.removeFromOrganization(id, this.organization.id);
        this.loadOrganizationMembers();
        this.alertSuccess(`${name} has been removed`);
      } catch (error) {
        this.alertError(error);
      }
    },

    async removeOrder(order) {
      try {
        this.removeOrderDialog.pending = true;
        const { id, name } = order;
        await this.$services.order.remove(id);
        await sleep(2);
        this.alertSuccess(`Removing ${name}...`);
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loadOrganizationOrders();
        this.removeOrderDialog.pending = false;
        this.removeOrderDialog.dialog = false;
      }
    },

    async removeFeature(feature) {
      try {
        this.removeFeatureDialog.pending = true;
        const { id, name } = feature;
        await this.$services.geometry.remove(id);
        this.alertSuccess(`${name} has been removed`);
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loadOrganizationFeatures();
        this.removeFeatureDialog.pending = false;
        this.removeFeatureDialog.dialog = false;
      }
    },

    async renameOrder(order) {
      try {
        this.rename.pending = true;
        const { id, name } = order;
        await this.$services.order.update(id, { name: this.rename.newName });
        this.alertSuccess(`${name} renamed to ${this.rename.newName} successfully`);
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loadOrganizationOrders();
        this.rename.pending = false;
        this.rename.dialog = false;
        this.rename.newName = '';
      }
    },

    async releaseOrder(order) {
      try {
        this.release.pending = true;
        const { id, name } = order;
        await this.$services.order.release(id);
        await sleep(2);
        this.alertSuccess(`${name} released successfully`);
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loadOrganizationOrders();
        this.release.pending = false;
        this.release.dialog = false;
      }
    },

    async rerunOrder(order) {
      try {
        this.rerun.pending = true;
        const { id, name } = order;
        await this.$services.order.rerun(id);
        await sleep(2);
        this.alertSuccess(`${name} reran successfully`);
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loadOrganizationOrders();
        this.rerun.pending = false;
        this.rerun.dialog = false;
      }
    },

    async archiveOrder(order) {
      try {
        this.archive.pending = true;
        const { id, name } = order;
        await this.$services.order.archive(id);
        await sleep(2);
        this.alertSuccess(`Archiving ${name}...`);
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loadOrganizationOrders();
        this.archive.pending = false;
        this.archive.dialog = false;
      }
    },

    async unarchiveOrder(order) {
      try {
        this.unarchive.pending = true;
        const { id, name } = order;
        await this.$services.order.unarchive(id);
        await sleep(2);
        this.alertSuccess(`Restoring ${name}...`);
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loadOrganizationOrders();
        this.unarchive.pending = false;
        this.unarchive.dialog = false;
      }
    },

    async deleteOrg() {
      try {
        this.deleting = true;
        const { id, name } = this.organization;
        await this.$services.organization.remove(id);
        this.alertSuccess(`${name} deleted`);
        this.deleting = false;
        this.$router.push({ name: 'organizations' });
      } catch (error) {
        this.alertError(error);
      }
    }
  },

  filters: {
    formatCost(value) {
      return value < 0.01 ? '$0.01' : `$${value.toFixed(2)}`;
    },

    formatDate(value) {
      return format(value, 'MMM d, yyyy');
    },

    formatProduct(value) {
      return value.toUpperCase();
    },

    formatPurpose(value) {
      if (value === 'studyarea') {
        return 'Study Area';
      }
      return value.toUpperCase();
    }
  }
};
</script>

<style lang="scss" scoped>
.delete-org-card {
  border: thin solid rgba(183, 28, 28, 0.12)
}
</style>
