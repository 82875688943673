<template>
  <v-container>
    <v-row>
      <v-checkbox label="Allow Advanced Options" v-model="value.advancedOptions" />
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({ advancedOptions: false })
    }
  }
};
</script>
