var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-container',{attrs:{"pa-0":""}},[(_vm.value)?_c('v-simple-table',{staticClass:"product-access-table",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Product")]),_c('th',{staticClass:"text-center"},[_vm._v("View")]),_c('th',{staticClass:"text-center"},[_vm._v("Create")]),_c('th',{staticClass:"text-center"},[_vm._v("Freeze")]),_c('th',{staticClass:"text-center",staticStyle:{"position":"relative"}},[_c('span',[_vm._v("Allowed Months")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"info-icon"},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","right":"","color":"accent"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])],1)]}}],null,false,1646585366)},[_c('span',[_vm._v("Restricted to selected months. No months selected gives open access.")])])],1),_c('th',{staticClass:"text-center"},[_vm._v("More Options")])])]),_c('tbody',_vm._l((_vm.products),function(ref){
var id = ref.id;
var name = ref.name;
var hasAdditionalOptions = ref.hasAdditionalOptions;
return _c('tr',{key:id},[_c('td',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.getAvatarColor(id)}},[_vm._v(_vm._s(_vm.getAvatarIcon(id)))]),_c('span',[_vm._v(_vm._s(name))])],1),_c('td',[_c('v-simple-checkbox',{attrs:{"color":"secondary","disabled":_vm.trial && !_vm.trialProducts.includes(id)},on:{"input":function($event){return _vm.checkWrite(id)}},model:{value:(_vm.value[id].read),callback:function ($$v) {_vm.$set(_vm.value[id], "read", $$v)},expression:"value[id].read"}})],1),_c('td',[_c('v-simple-checkbox',{attrs:{"color":"secondary","disabled":_vm.trial && !_vm.trialProducts.includes(id)},on:{"input":function($event){return _vm.checkRead(id)}},model:{value:(_vm.value[id].write),callback:function ($$v) {_vm.$set(_vm.value[id], "write", $$v)},expression:"value[id].write"}})],1),_c('td',[_c('v-simple-checkbox',{attrs:{"color":"secondary","disabled":_vm.trial && !_vm.trialProducts.includes(id)},model:{value:(_vm.value[id].freeze),callback:function ($$v) {_vm.$set(_vm.value[id], "freeze", $$v)},expression:"value[id].freeze"}})],1),_c('td',[_c('allowed-months',{attrs:{"disabled":_vm.trial && !_vm.trialProducts.includes(id)},model:{value:(_vm.value[id].allowedMonths),callback:function ($$v) {_vm.$set(_vm.value[id], "allowedMonths", $$v)},expression:"value[id].allowedMonths"}})],1),_c('td',[_c('additional-options',{attrs:{"disabled":!hasAdditionalOptions || (_vm.trial && !_vm.trialProducts.includes(id))}},[_c((id + "-additional-options"),{tag:"component",model:{value:(_vm.value[id].additional),callback:function ($$v) {_vm.$set(_vm.value[id], "additional", $$v)},expression:"value[id].additional"}})],1)],1)])}),0)]},proxy:true}],null,false,3644391177)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }