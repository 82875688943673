<template>
  <v-container dark fluid fill-height>
    <v-row justify="center">
      <v-progress-circular color="primary" size="64" indeterminate />
    </v-row>
  </v-container>
</template>

<script>
import alert from '@/mixins/alert';

export default {
  mixins: [alert],

  async created() {
    try {
      await this.$auth.handleAuthentication();
      this.$router.push('/');
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
      this.alertError(error);
    }
  }
};
</script>
