<template>
  <v-app id="app">
    <template v-if="ready">
      <v-navigation-drawer app permanent>
        <v-toolbar flat dark color="dark">
          <v-list class="pa-0">
            <v-list-item>
              <v-list-item-action>
                <img src="~@/assets/img/logo_long.png" height="45px" />
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-toolbar>
        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ userName }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list nav dense>
          <v-list-item @click="goTo('dashboard')">
            <v-list-item-action>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="goTo('users')">
            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Users</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="goTo('organizations')">
            <v-list-item-action>
              <v-icon>mdi-domain</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Organizations</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item @click="goTo('products')">
            <v-list-item-action>
              <v-icon>mdi-creation</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Products</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
        <v-divider />
        <v-spacer />
        <v-list-item @click="logout">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sign Out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      </v-navigation-drawer>
      <v-main key="app-content">
        <router-view />
      </v-main>
    </template>
    <template v-else>
      <v-container dark fluid fill-height key="app-loading">
        <v-row justify="center" fill-height>
          <v-progress-circular color="primary" size="64" indeterminate />
        </v-row>
      </v-container>
    </template>
    <v-snackbar v-model="snackbar" :color="alert.color" :timeout="alert.timeout" key="app-snackbar">
      <span>{{ alert.message }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import Router from 'vue-router';
import { mapState, mapGetters, mapActions } from 'vuex';
import alert from '@/mixins/alert';

const STORAGE_KEY = 'airsageAdmin';

export default {
  name: 'App',

  mixins: [alert],

  data() {
    return {
      snackbar: false
    };
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },

    ...mapState(['alert', 'ready']),
    ...mapGetters(['userId', 'userName', 'isAdmin'])
  },

  async created() {
    try {
      await this.$auth.renewTokens();
    } catch (error) {
      this.$auth.login();
    }

    try {
      const { mini = true } = JSON.parse(localStorage.getItem(STORAGE_KEY)) || {};
      this.mini = mini;
    } catch (error) {
      this.alertError(error);
    }
  },

  watch: {
    alert: {
      deep: true,
      handler() {
        if (this.alert.color && this.alert.message && this.alert.message.length > 0) {
          this.snackbar = true;
        }
      }
    },

    mini(mini) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify({ mini }));
    }
  },

  methods: {
    async handleLoginEvent(data) {
      try {
        if (!data.loggedIn) {
          this.alertError('Not Authorized');
          this.$auth.logOut();
        }
        await this.setUser(data.profile);
      } catch (error) {
        this.alertError(error);
      } finally {
        this.setReady(true);
      }
    },

    goTo(name) {
      this.$router.push({ name }).catch((error) => {
        if (!Router.isNavigationFailure(error, Router.NavigationFailureType.duplicated)) {
          throw error;
        }
      });
    },

    logout() {
      this.$auth.logOut();
    },

    ...mapActions(['setUser', 'setReady'])
  }
};
</script>
