<template>
  <v-form v-model="formValid">
    <v-container>
      <v-row wrap>
        <v-col>
          <v-text-field label="Credits" type="number" min="0" step="1" hide-details v-model="value.credits" @input="hasChanged = true"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-fade-transition>
            <v-textarea outlined label="Note" v-model="description" v-show="hasChanged" />
          </v-fade-transition>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import formValidation from '@/mixins/validation';

export default {
  mixins: [formValidation],

  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      description: '',
      hasChanged: false
    };
  },

  methods: {
    reset() {
      this.hasChanged = false;
      this.description = '';
    }
  }
};
</script>
