<template>
  <v-container pa-0>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-card :loading="loading">
            <v-card-title>Product Details</v-card-title>
            <v-card-text>
              <v-form v-model="formValid">
                <v-container>
                  <v-row wrap>
                    <v-col>
                      <v-text-field label="ID" v-model="product.id" disabled />
                    </v-col>
                    <v-col>
                      <v-text-field label="Name" v-model="product.name" :rules="rules.name" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="primary darken-1" text @click="save" :loading="pending" :disabled="errors">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import alert from '@/mixins/alert';
import formValidation, { name } from '@/mixins/validation';

export default {
  mixins: [alert, formValidation, name],

  data() {
    return {
      product: { features: {} },
      pending: false,
      isMounted: false,
      loading: false
    };
  },

  mounted() {
    this.isMounted = true;
  },

  computed: {
    canSave() {
      return this.isMounted && !this.errors;
    }
  },

  async beforeRouteEnter(to, from, next) {
    next(async vm => await vm.loadProduct(to.params.id));
  },

  async beforeRouteUpdate(to, from, next) {
    this.product = { features: {} };
    await this.loadProduct(to.params.id);
    next();
  },

  methods: {
    async loadProduct(id) {
      try {
        this.loading = true;
        const { data: { product } } = await this.$services.product.read(id);
        this.product = product;
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loading = false;
      }
    },

    async save() {
      if (this.canSave) {
        try {
          this.pending = true;
          const { id, name } = this.product;
          await this.$services.product.update(id, { name });
          this.alertSuccess(`${this.product.name} updated`);
        } catch (error) {
          this.alertError(error);
        } finally {
          this.pending = false;
        }
      }
    }
  }
};
</script>
