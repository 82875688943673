<template>
  <v-form v-model="formValid">
    <v-container pa-0>
      <v-simple-table class="product-access-table" v-if="value">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Product</th>
              <th class="text-center">View</th>
              <th class="text-center">Create</th>
              <th class="text-center">Freeze</th>
              <th class="text-center" style="position: relative;">
                <span>Allowed Months</span>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="info-icon">
                      <v-icon small right color="accent" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                    </div>
                  </template>
                  <span>Restricted to selected months. No months selected gives open access.</span>
                </v-tooltip>
              </th>
              <th class="text-center">More Options</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="{ id, name, hasAdditionalOptions } in products" :key="id">
              <td>
                <v-icon class="mr-2" :color="getAvatarColor(id)">{{ getAvatarIcon(id) }}</v-icon>
                <span>{{ name }}</span>
              </td>
              <td>
                <v-simple-checkbox color="secondary" v-model="value[id].read" @input="checkWrite(id)" :disabled="trial && !trialProducts.includes(id)" />
              </td>
              <td>
                <v-simple-checkbox color="secondary" v-model="value[id].write" @input="checkRead(id)" :disabled="trial && !trialProducts.includes(id)" />
              </td>
              <td>
                <v-simple-checkbox color="secondary" v-model="value[id].freeze" :disabled="trial && !trialProducts.includes(id)" />
              </td>
              <td>
                <allowed-months v-model="value[id].allowedMonths" :disabled="trial && !trialProducts.includes(id)" />
              </td>
              <td>
                <additional-options :disabled="!hasAdditionalOptions || (trial && !trialProducts.includes(id))">
                  <component :is="`${id}-additional-options`" v-model="value[id].additional" />
                </additional-options>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
  </v-form>
</template>

<script>
import get from 'lodash/get';
import formValidation from '@/mixins/validation';
import studyIcon from '@/mixins/studyIcon';
import AllowedMonths from '@/components/AllowedMonths';
import AdditionalOptions from '@/components/AdditionalOptions';

export default {
  mixins: [formValidation, studyIcon],

  components: {
    AllowedMonths,
    AdditionalOptions
  },

  props: {
    value: {
      type: Object,
      default: () => {}
    },

    trial: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      products: [{
        id: 'tla',
        name: 'Targeted Location Analysis',
        hasAdditionalOptions: true,
        supportsTrial: false
      }, {
        id: 'dla',
        name: 'Destination Location Analysis',
        hasAdditionalOptions: true,
        supportsTrial: true
      }, {
        id: 'ad',
        name: 'Activity Density',
        hasAdditionalOptions: true,
        supportsTrial: false
      }, {
        id: 'nwtm',
        name: 'Nationwide Trip Matrix',
        hasAdditionalOptions: true,
        supportsTrial: false
      }, {
        id: 'pp',
        name: 'Point Plot',
        hasAdditionalOptions: false,
        supportsTrial: false
      }]
    };
  },

  computed: {
    trialProducts() {
      return this.products.filter(p => p.supportsTrial).map(p => p.id);
    }
  },

  methods: {
    checkWrite(product) {
      const { read = false, write = false } = get(this.value, product, {});
      if (!read && write) {
        this.value[product].write = false;
      }
    },

    checkRead(product) {
      const { read = false, write = false } = get(this.value, product, {});
      if (write && !read) {
        this.value[product].read = true;
      }
    }
  }
};
</script>

<style lang="scss">
.product-access-table td:not(:first-child) {
  text-align: center;
}

.info-icon {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(-75%);
}
</style>
