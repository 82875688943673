import Service from '@/services/service';

export default class OrderService extends Service {
  constructor(token) {
    super('orders', token);
  }

  rerun(id, options) {
    return this.service.get(`/${id}/rerun`, options);
  }

  release(id, override = {}, options) {
    return this.service.post(`/${id}/release`, override, options);
  }

  archive(id, options) {
    return this.service.get(`/${id}/archive`, options);
  }

  unarchive(id, options) {
    return this.service.get(`/${id}/unarchive`, options);
  }

  getStatistics() {
    return this.service.get('/statistics');
  }
}
