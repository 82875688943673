import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [{
  path: '/dashboard',
  name: 'dashboard',
  meta: {
    requiresAuth: true
  },
  component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard')
}, {
  path: '/users',
  name: 'users',
  meta: {
    requiresAuth: true
  },
  component: () => import(/* webpackChunkName: "users" */ '@/views/Users')
}, {
  path: '/users/:id',
  name: 'user',
  meta: {
    requiresAuth: true
  },
  component: () => import(/* webpackChunkName: "user" */ '@/views/User')
}, {
  path: '/organizations',
  name: 'organizations',
  meta: {
    requiresAuth: true
  },
  component: () => import(/* webpackChunkName: "organizations" */ '@/views/Organizations')
}, {
  path: '/organizations/:id',
  name: 'organization',
  meta: {
    requiresAuth: true
  },
  component: () => import(/* webpackChunkName: "organization" */ '@/views/Organization')
}, {
  path: '/products',
  name: 'products',
  meta: {
    requiresAuth: true
  },
  component: () => import(/* webpackChunkName: "products" */ '@/views/Products')
}, {
  path: '/products/:id',
  name: 'product',
  meta: {
    requiresAuth: true
  },
  component: () => import(/* webpackChunkName: "product" */ '@/views/Product')
}, {
  path: '/callback',
  name: 'callback',
  component: () => import(/* webpackChunkName: "callback" */ '@/components/Callback')
}, {
  path: '*',
  redirect: '/dashboard'
}];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// check auth
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = Vue.prototype.$auth.isAuthenticated();
  if (requiresAuth && !isAuthenticated) {
    Vue.prototype.$auth.login({ target: to.path });
  } else {
    next();
  }
});

export default router;
