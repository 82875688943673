import AuthService from '@/lib/authService';

export default {
  install(Vue, config) {
    Vue.prototype.$auth = new AuthService(config);

    Vue.mixin({
      created() {
        if (this.handleLoginEvent) {
          Vue.prototype.$auth.addListener('login', this.handleLoginEvent);
        }
      },

      destroyed() {
        if (this.handleLoginEvent) {
          Vue.prototype.$auth.removeListener('login', this.handleLoginEvent);
        }
      }
    });
  }
};
