export default {
  methods: {
    getAvatarColor(product) {
      let color;
      product = product.toLowerCase();
      if (product === 'dla') {
        color = '#00A237';
      } else if (product === 'tla') {
        color = '#DE7200';
      } else if (product === 'ad') {
        color = '#FFB606';
      } else if (product === 'nwtm') {
        color = '#02698B';
      } else if (product === 'pp') {
        color = '#27233A';
      } else {
        color = '#CDCDCD';
      }
      return color;
    },

    getAvatarIcon(product) {
      let icon;
      product = product.toLowerCase();
      if (product === 'dla') {
        icon = 'mdi-map-marker-circle';
      } else if (product === 'tla') {
        icon = 'mdi-map-marker-outline';
      } else if (product === 'ad') {
        icon = 'mdi-grid';
      } else if (product === 'nwtm') {
        icon = 'mdi-data-matrix';
      } else if (product === 'pp') {
        icon = 'mdi-scatter-plot';
      } else {
        icon = 'mdi-text-box-outline';
      }
      return icon;
    },

    getStatusColor(status) {
      let color;
      switch (status) {
        case 'SUCCEEDED': case 'UNARCHIVING':
          color = '#4CAF50';
          break;

        case 'ARCHIVED': case 'ARCHIVING':
          color = '#0000008A';
          break;

        case 'FAILED': case 'DELETING':
          color = '#B71C1C';
          break;

        default:
          color = '#1675D1';
      }
      return color;
    },

    getStatusIcon(status) {
      let icon;
      if (status === 'SUCCEEDED') {
        icon = 'mdi-check';
      } else if (status === 'QUEUED') {
        icon = 'mdi-tray-full';
      } else if (status === 'ARCHIVED') {
        icon = 'mdi-archive-outline';
      } else if (status === 'FAILED') {
        icon = 'mdi-alert-circle-outline';
      } else {
        icon = 'mdi-checkbox-blank-circle-outline';
      }
      return icon;
    }
  }
};
