<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <quick-start-card />
      </v-col>
      <v-col cols="4">
        <order-statistics-card />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import QuickStartCard from '@/components/QuickStartCard';
import OrderStatisticsCard from '@/components/OrderStatisticsCard';

export default {
  components: {
    QuickStartCard,
    OrderStatisticsCard
  }
};
</script>
