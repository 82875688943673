<template>
  <v-card outlined>
    <v-carousel continuous cycle :show-arrows="false" hide-delimiter-background delimiter-icon="mdi-minus" light height="100%">
      <v-carousel-item v-for="d in data" :key="d.product">
        <v-card-title>Number of {{ d.product.toUpperCase() }} Studies</v-card-title>
        <v-card-text class="mb-5 pb-5">
          <v-row align="center">
            <v-col cols="6">
              <v-icon :color="getAvatarColor(d.product)" size="72">{{ getAvatarIcon(d.product) }}</v-icon>
            </v-col>
            <v-col class="text-h1" cols="6">{{ d.total }}</v-col>
          </v-row>
        </v-card-text>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>

<script>
import studyIcon from '@/mixins/studyIcon';
import alert from '@/mixins/alert';

export default {
  mixins: [studyIcon, alert],

  data() {
    return {
      loading: false,
      data: []
    };
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      try {
        this.loading = true;
        const { data } = await this.$services.order.getStatistics();
        this.data = data;
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
