import Vue from 'vue';
import Vuex from 'vuex';
import { configureScope } from '@sentry/browser';

Vue.use(Vuex);

const debug = process.env.NODE_ENV === 'development';

export default new Vuex.Store({
  strict: debug,

  state: {
    user: {},
    ready: false,
    alert: {
      color: 'error',
      message: '',
      timeout: 6000
    },
    offsetTop: 0,
    filter: {
      tags: [],
      organizations: [],
      terms: '',
      dates: []
    }
  },

  getters: {
    userEmail: state => state.user.email,
    userName: state => state.user.name,
    userId: state => state.user.sub.split('|')[1],
    isAdmin: state => (state.user['http://airsage.com/roles'] || []).includes('Jacquerie Admin'),
    hasScrolled: state => state.offsetTop > 0
  },

  actions: {
    setUser({ commit }, user) {
      configureScope((scope) => {
        const { sub, email, name: username } = user;
        const id = sub.split('|')[1];
        scope.setUser({ id, email, username });
      });
      commit('SET_USER', user);
    },

    setAlert({ commit }, alert) {
      commit('SET_ALERT', alert);
    },

    setOffsetTop({ commit }, offset) {
      commit('SET_OFFSET_TOP', offset);
    },

    setFilter({ commit }, filter) {
      commit('SET_FILTER', filter);
    },

    setReady({ commit }, ready) {
      commit('SET_READY', ready);
    }
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },

    SET_ALERT(state, alert) {
      const { color, message, timeout = 6000 } = alert;
      state.alert = {
        color,
        message,
        timeout: color === 'error' ? 0 : timeout
      };
    },

    SET_OFFSET_TOP(state, offset) {
      state.offsetTop = offset;
    },

    SET_FILTER(state, filter) {
      state.filter = filter;
    },

    SET_READY(state, ready) {
      state.ready = ready;
    }
  }
});
