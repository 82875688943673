<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card outlined :loading="loading">
          <v-data-table :headers="headers" :items="users" :search="search" @click:row="goToUser" :items-per-page="-1" hide-default-footer fixed-header>
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Users</v-toolbar-title>
                <v-spacer />
                <v-text-field v-model="search" prepend-icon="mdi-magnify" label="Search" single-line hide-details />
                <v-spacer />
                <v-dialog v-model="showCreate" max-width="500px" persistent>
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" depressed v-on="on">Create User</v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Create User</span>
                    </v-card-title>
                    <v-card-text>
                      <v-form v-model="formValid">
                        <v-container>
                          <v-row>
                            <v-text-field label="Name" v-model="newUser.name" :rules="rules.name" />
                          </v-row>
                          <v-row>
                            <v-text-field label="Email" v-model="newUser.email" :rules="rules.email" />
                          </v-row>
                          <v-row>
                            <v-text-field label="Password" v-model="newUser.password" :rules="rules.password" type="password" />
                          </v-row>
                        </v-container>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="error" text @click="close">Cancel</v-btn>
                      <v-spacer />
                      <v-btn color="primary" text @click="save" :disabled="errors">Save</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:items="props">
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.email }}</td>
            </template>
            <template v-slot:item.action="{ item }">
              <div @click.stop="">
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-btn class="user-menu" icon v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="goToUser(item)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="unblockUser(item)" v-if="item.blocked">
                      <v-list-item-title>Unblock</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="blockUser(item)" v-else>
                      <v-list-item-title>Block</v-list-item-title>
                    </v-list-item>
                    <v-divider />
                    <v-list-item @click="removeUser(item)">
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showRemove" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Remove User</span>
        </v-card-title>
        <v-card-text>
          <p>Are you sure you want to remove {{ userToBeDeleted.name }}?</p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="closeRemove">Cancel</v-btn>
          <v-spacer />
          <v-btn color="error" text @click="remove">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import alert from '@/mixins/alert';
import formValidation, { name, email, password } from '@/mixins/validation';

export default {
  mixins: [alert, formValidation, name, email, password],

  data() {
    return {
      users: [],
      search: '',
      loading: false,
      headers: [{
        text: 'Name',
        align: 'left',
        sortable: false,
        value: 'name'
      }, {
        text: 'Email',
        align: 'left',
        sortable: false,
        value: 'email'
      }, {
        text: '',
        value: 'action',
        sortable: false
      }],
      showCreate: false,
      newUser: {
        name: '',
        email: '',
        password: ''
      },
      showRemove: false,
      userToBeDeleted: {}
    };
  },

  async mounted() {
    this.loadUsers();
  },

  methods: {
    async loadUsers() {
      try {
        this.loading = true;
        const { data } = await this.$services.user.all();
        this.users = data;
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loading = false;
      }
    },

    goToUser(user) {
      const { id } = user;
      this.$router.push({ name: 'user', params: { id } });
    },

    async save() {
      if (!this.errors) {
        try {
          this.pending = true;
          await this.$services.user.create(this.newUser);
          this.alertSuccess(`${this.newUser.name} created`);
        } catch (error) {
          this.alertError(error);
        } finally {
          this.pending = false;
          this.close();
          this.loadUsers();
        }
      }
    },

    removeUser(user) {
      this.userToBeDeleted = user;
      this.showRemove = true;
    },

    async remove() {
      try {
        this.pending = true;
        await this.$services.user.remove(this.userToBeDeleted.id);
        this.alertSuccess(`${this.userToBeDeleted.name} removed`);
      } catch (error) {
        this.alertError(error);
      } finally {
        this.pending = false;
        this.closeRemove();
      }
    },

    close() {
      this.showCreate = false;
      this.newUser = {
        name: '',
        email: '',
        password: ''
      };
    },

    closeRemove() {
      this.showRemove = false;
      this.userToBeDeleted = {};
    },

    async blockUser(user) {
      try {
        const { id: uid, name } = user;
        await this.$services.user.block(uid);
        await this.loadUsers();
        this.alertSuccess(`${name} has been blocked`);
      } catch (error) {
        this.alertError(error);
      }
    },

    async unblockUser(user) {
      try {
        const { id: uid, name } = user;
        await this.$services.user.unblock(uid);
        await this.loadUsers();
        this.alertSuccess(`${name} has been unblocked`);
      } catch (error) {
        this.alertError(error);
      }
    }
  }
};
</script>
