<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card outlined :loading="loading">
          <v-data-table :headers="headers" :items="organizations" :search="search" @click:row="goToOrganization" :items-per-page="-1" hide-default-footer fixed-header>
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Organizations</v-toolbar-title>
                <v-spacer />
                <v-text-field v-model="search" prepend-icon="mdi-magnify" label="Search" single-line hide-details />
                <v-spacer />
                <v-dialog v-model="showCreate" max-width="500px" persistent>
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" depressed v-on="on">Create Organization</v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Create Organization</span>
                    </v-card-title>
                    <v-card-text>
                      <basic-organization-config ref="newOrganizationForm" v-model="newOrganization" />
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="error" text @click="close">Cancel</v-btn>
                      <v-spacer />
                      <v-btn color="primary" text @click="save" :disabled="formErrors">Save</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:items="props">
              <td>{{ props.item.name }}</td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import alert from '@/mixins/alert';
import BasicOrganizationConfig from '@/components/BasicOrganizationConfig';

export default {
  mixins: [alert],

  components: {
    BasicOrganizationConfig
  },

  data() {
    return {
      isMounted: false,
      organizations: [],
      search: '',
      loading: false,
      headers: [{
        text: 'Name',
        align: 'left',
        sortable: false,
        value: 'name'
      }],
      showCreate: false,
      newOrganization: {
        name: '',
        email: ''
      }
    };
  },

  computed: {
    formErrors() {
      return this.isMounted && this.$refs.newOrganizationForm.errors;
    }
  },

  async mounted() {
    this.loadOrganizations();
  },

  watch: {
    showCreate() {
      this.$nextTick(() => {
        this.isMounted = this.showCreate;
      });
    }
  },

  methods: {
    async loadOrganizations() {
      try {
        this.loading = true;
        const { data } = await this.$services.organization.all();
        this.organizations = data;
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loading = false;
      }
    },

    goToOrganization(user) {
      const { id } = user;
      this.$router.push({ name: 'organization', params: { id } });
    },

    async save() {
      if (!this.errors) {
        try {
          this.pending = true;
          await this.$services.organization.create(this.newOrganization);
          this.alertSuccess(`${this.newOrganization.name} created`);
        } catch (error) {
          this.alertError(error);
        } finally {
          this.pending = false;
          this.close();
          this.loadOrganizations();
        }
      }
    },

    close() {
      this.showCreate = false;
      this.newOrganization = {
        name: '',
        email: ''
      };
    }
  },

  filters: {
    formatCurrency(value) {
      return `$${(value / 100).toLocaleString('en-US', { useGrouping: true, maximumFractionDigits: 2 })}`;
    }
  }
};
</script>
