<template>
  <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="475px" max-width="475px">
    <template v-slot:activator="{ on, attrs }">
      <v-badge bordered :color="negated ? 'error' : 'dark'" :value="numAllowedMonths > 0" :content="numAllowedMonths" overlap>
        <v-btn class="product-access-icon" v-bind="attrs" v-on="on" :color="color" :disabled="disabled" icon outlined>
          <v-icon>mdi-calendar</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <v-card-text>
        <v-combobox v-model="allowedMonthsText" multiple chips small-chips label="Dates" prepend-icon="mdi-calendar" readonly>
          <template v-slot:selection="data">
            <v-chip v-bind="data.attrs" close @click:close="removeDates(data.index)">{{ data.item }}</v-chip>
          </template>
        </v-combobox>
      </v-card-text>
      <v-date-picker ref="picker" width="475" v-model="newDates" :min="earliestDate" :max="latestDate" :allowed-dates="allowedDates" no-title type="month" range>
        <v-container py-0>
          <v-row>
            <v-col>
              <v-btn color="accent" outlined @click="newDates = []">Clear</v-btn>
            </v-col>
            <v-spacer/>
            <v-col cols="3">
              <v-btn color="primary" depressed @click="addDates(newDates)">Add</v-btn>
            </v-col>
          </v-row>
          <v-divider/>
          <v-row no-gutters>
            <v-col>
              <v-checkbox color="secondary" label="Negate Selection" v-model="negated" />
            </v-col>
          </v-row>
        </v-container>
      </v-date-picker>
    </v-card>
  </v-menu>
</template>

<script>
import parseISO from 'date-fns/parseISO';
import formatDate from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import minDate from 'date-fns/min';
import maxDate from 'date-fns/max';
import eachMonthOfInterval from 'date-fns/eachMonthOfInterval';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';

export default {
  props: {
    value: {
      type: Object,
      default: () => ({ months: [], negate: false })
    },

    color: {
      type: String,
      default: 'primary'
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      menu: false,
      ingestedMonths: [],
      newDates: []
    };
  },

  computed: {
    allowedMonths: {
      get() {
        return this.value.months;
      },

      set(months) {
        this.$emit('input', {
          months,
          negate: this.negated
        });
      }
    },

    negated: {
      get() {
        return this.value.negate;
      },

      set(negate) {
        this.$emit('input', {
          months: this.allowedMonths,
          negate
        });
      }
    },

    allowedMonthsText() {
      return this.allowedMonths.map(([start, end]) => {
        start = formatDate(parseISO(start), 'MMM yyyy');
        if (end) {
          end = formatDate(parseISO(end), 'MMM yyyy');
          return `${start} - ${end}`;
        }
        return start;
      });
    },

    numAllowedMonths() {
      let numMonths = 0;
      if (this.allowedMonths.length > 0) {
        const allowedMonths = flatten(this.allowedMonths.map((dr) => {
          let [start, end] = dr.map(d => parseISO(d));
          if (!end) {
            return formatDate(start, 'yyyy-MM');
          }
          let pickedDates = eachMonthOfInterval({ start, end });
          pickedDates = pickedDates.map(d => formatDate(d, 'yyyy-MM'));
          return pickedDates;
        }));
        numMonths = allowedMonths.length;
      }
      return numMonths;
    },

    earliestDate() {
      if (!isEmpty(this.ingestedMonths)) {
        const earliestIngested = minDate(this.ingestedMonths.map(d => parseISO(d)));
        return formatDate(earliestIngested, 'yyyy-MM');
      }
      return undefined;
    },

    latestDate() {
      if (!isEmpty(this.ingestedMonths)) {
        const latestIngested = maxDate(this.ingestedMonths.map(d => parseISO(d)));
        return formatDate(latestIngested, 'yyyy-MM');
      }
      return undefined;
    }
  },

  async mounted() {
    try {
      const { data } = await this.$services.ingest.all();
      this.ingestedMonths = data.map(d => formatDate(parseISO(d), 'yyyy-MM'));
    } catch (error) {
      this.alertError(error);
    }
  },

  methods: {
    addDates() {
      if (this.newDates.length) {
        if (this.newDates.length > 1) {
          let [start, end] = this.newDates.map(d => parseISO(d));
          if (isBefore(end, start)) {
            const start2 = start;
            start = end;
            end = start2;
            this.newDates = [start, end].map(d => formatDate(d, 'yyyy-MM'));
          }
        }
        this.allowedMonths.push(this.newDates);
      }
      this.newDates = [];
    },

    removeDates(index) {
      this.allowedMonths.splice(index, 1);
    },

    currentlyPicking(date) {
      if (isEmpty(this.allowedMonths)) {
        return false;
      }

      const allowedMonths = flatten(this.allowedMonths.map((dr) => {
        let [start, end] = dr.map(d => parseISO(d));
        date = formatDate(parseISO(date), 'yyyy-MM');
        if (!end) {
          return formatDate(start, 'yyyy-MM');
        }
        let pickedDates = eachMonthOfInterval({ start, end });
        pickedDates = pickedDates.map(d => formatDate(d, 'yyyy-MM'));
        return pickedDates;
      }));
      return allowedMonths.includes(date);
    },

    allowedDates(date) {
      return !this.currentlyPicking(date);
    }
  }
};
</script>
