<template>
  <v-card outlined :loading="loading">
    <v-card-title class="text-h5">API Key</v-card-title>
    <v-card-text>
      <v-container>
        <v-row wrap>
          <v-col>
            <v-text-field type="text" v-model="apiKey" readonly append-icon="mdi-eye"
              @click:append="loadOrganizationAPIKey" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on }">
          <v-btn color="primary darken-1" text v-on="on">New API Key</v-btn>
        </template>
        <v-card>
          <v-card-title primary-title>New API Key</v-card-title>
          <v-card-text class="text-body-1">Are you sure you want to issue a new API Key?</v-card-text>
          <v-card-text>
            <v-alert border="left" type="warning" dense text outlined>This will invalidate any previous keys.</v-alert>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" text @click="dialog = false">Cancel</v-btn>
            <v-spacer />
            <v-btn color="primary" depressed :loading="pending" @click="issueNewAPIKey">Issue</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import alert from '@/mixins/alert';

export default {
  mixins: [alert],

  data() {
    return {
      loading: false,
      apiKey: '********************************************************',
      pending: false,
      dialog: false
    };
  },

  methods: {
    async loadOrganizationAPIKey() {
      try {
        this.loading = true;
        const { data } = await this.$services.organization.getAPIKey(this.$route.params.id);
        this.apiKey = data;
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loading = false;
      }
    },

    async issueNewAPIKey() {
      try {
        this.pending = true;
        const { data } = await this.$services.organization.issueAPIKey(this.$route.params.id);
        this.apiKey = data;
        this.dialog = false;
        this.alertSuccess('New API key issued');
      } catch (error) {
        this.alertError(error);
      } finally {
        this.pending = false;
      }
    }
  }
};
</script>
