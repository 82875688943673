<template>
  <v-card outlined>
    <v-card-title class="headline">Need to Start From Scratch?</v-card-title>
    <v-card-text>
      <p>Create a new user along with a new organization</p>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-dialog v-model="dialog" max-width="500px" persistent>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" depressed v-on="on">Get Started</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline" main-title>Quick Start</span>
          </v-card-title>
          <v-card-text>
            <v-form v-model="formValid">
              <v-container>
                <v-row>
                  <v-text-field label="Name" v-model="user.name" :rules="rules.name" />
                </v-row>
                <v-row>
                  <v-text-field label="Email" v-model="user.email" :rules="rules.email" />
                </v-row>
                <v-row>
                  <v-text-field label="Password" v-model="user.password" :rules="rules.password" type="password" />
                </v-row>
                <v-row>
                  <v-text-field label="Organization Name" v-model="organization.name" :rules="rules.name" />
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" text @click="cancel">Cancel</v-btn>
            <v-spacer />
            <v-btn color="primary" text @click="create" :loading="pending" :disabled="errors">Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import alert from '@/mixins/alert';
import formValidation, { name, email, password } from '@/mixins/validation';

export default {
  mixins: [alert, formValidation, name, email, password],

  data() {
    return {
      user: {
        name: '',
        email: '',
        password: ''
      },
      organization: {
        name: '',
        email: ''
      },
      pending: false,
      dialog: false
    };
  },

  methods: {
    async create() {
      try {
        this.pending = true;
        // create user
        const { data: newUser } = await this.$services.user.create(this.user);
        this.organization.email = this.user.email;
        // create organization
        const { data: newOrganization } = await this.$services.organization.create(this.organization);
        // add user to org
        await this.$services.user.addToOrganization(newUser.id, newOrganization.id, 'owner');

        this.alertSuccess(`${newOrganization.name} successfully created with ${this.user.name}`);
        this.cancel();
      } catch (error) {
        this.alertError(error);
      } finally {
        this.pending = false;
      }
    },

    cancel() {
      this.user = {
        name: '',
        email: '',
        password: ''
      };
      this.organization = {
        name: '',
        email: ''
      };
      this.dialog = false;
    }
  }
};
</script>
