<template>
  <v-form v-model="formValid">
    <v-container>
      <v-row>
        <v-col>
          <v-text-field label="Name" v-model="value.name" :rules="rules.name" hide-details />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field label="Primary Email" v-model="value.email" :rules="rules.email" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-switch label="Freemium" color="secondary" v-model="value.trial" inset />
        </v-col>
        <v-col>
          <v-switch label="Use Legacy Data" color="secondary" v-model="value.useLegacyData" inset disabled />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import formValidation, { name, email } from '@/mixins/validation';

export default {
  mixins: [formValidation, name, email],

  props: {
    value: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
