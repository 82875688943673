import UserService from './user';
import GeometryService from './geometry';
import OrganizationService from './organization';
import ProductService from './product';
import OrderService from './order';
import IngestService from './ingest';

export default {
  install(Vue) {
    const services = {
      user: new UserService(),
      organization: new OrganizationService(),
      geometry: new GeometryService(),
      product: new ProductService(),
      order: new OrderService(),
      ingest: new IngestService()
    };

    Vue.prototype.$services = services;
  }
};
