import Vue from 'vue';
import querystring from 'querystring';
import axios from 'axios';
import { apiUrl } from '@/config';

export default class Service {
  constructor(resource) {
    this.service = axios.create({
      baseURL: `${apiUrl}/${resource}`,
      headers: {
        Accept: 'application/json'
      },
      paramsSerializer(params) {
        return querystring.stringify(params);
      }
    });

    this.service.interceptors.request.use(async (config) => {
      const token = await Vue.prototype.$auth.getAccessToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
  }

  all(options) {
    return this.service.get('', options);
  }

  read(id, options) {
    return this.service.get(`/${id}`, options);
  }

  create(obj, options) {
    return this.service.post('', obj, options);
  }

  update(id, obj, options) {
    return this.service.patch(`/${id}`, obj, options);
  }

  remove(id, options) {
    return this.service.delete(`/${id}`, options);
  }
}
