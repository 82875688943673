import { name, version } from '../../package';

const {
  VUE_APP_AUTH0_CLIENT_ID: clientID,
  VUE_APP_AUTH0_DOMAIN: domain,
  VUE_APP_AUTH0_API_IDENTIFIER: audience,
  VUE_APP_AUTH0_CONNECTION: connection,
  VUE_APP_SENTRY_DSN: dsn,
  VUE_APP_API_URL: apiURL
} = process.env;

export const app = {
  name,
  version,
  environment: apiURL.slice(-3) === 'com' ? 'production' : 'development'
};
export const sentry = {
  dsn
};
export const auth0Config = {
  domain,
  clientID,
  audience,
  connection
};
export const apiUrl = apiURL;
