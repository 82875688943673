<template>
  <v-container>
    <v-row>
      <v-checkbox label="Override 10m Grid Restrictions" v-model="value.override10m" />
    </v-row>
    <v-row>
      <v-checkbox label="Allow Visualization" v-model="value.allowVisualization" />
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        override10m: false,
        allowVisualization: true
      })
    }
  }
};
</script>
