import axios from 'axios';
import Service from '@/services/service';

export default class OrganizationService extends Service {
  constructor(token) {
    super('users', token);
    this.CancelToken = axios.CancelToken;
    this.isCancel = axios.isCancel;
  }

  search(options) {
    return this.service.get('search', options);
  }

  listOrganizations(uid, options) {
    return this.service.get(`/${uid}/organizations`, options);
  }

  addToOrganization(uid, oid, role, options) {
    return this.service.post(`/${uid}/organizations/${oid}`, { role }, options);
  }

  removeFromOrganization(uid, oid, options) {
    return this.service.delete(`/${uid}/organizations/${oid}`, options);
  }

  updateRoleInOrganization(uid, oid, role, options) {
    return this.service.patch(`/${uid}/organizations/${oid}`, { role }, options);
  }

  block(uid, options) {
    return this.service.post(`/${uid}/block`, options);
  }

  unblock(uid, options) {
    return this.service.post(`/${uid}/unblock`, options);
  }
}
