<template>
  <v-container pa-0>
    <v-container fluid mb-2>
        <v-row>
          <v-col>
            <v-card :loading="loading">
              <v-data-table :headers="headers" :items="products" @click:row="goToProduct" :items-per-page="-1" hide-default-footer fixed-header>
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-toolbar-title>Products</v-toolbar-title>
                    <v-spacer />
                    <v-dialog v-model="showCreate" max-width="500px" persistent>
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" depressed v-on="on">Create Product</v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Create Product</span>
                        </v-card-title>
                        <v-card-text>
                          <v-form v-model="formValid">
                            <v-container>
                              <v-row wrap>
                                <v-col>
                                  <v-text-field label="ID" v-model="newProduct.id" :rules="rules.id" />
                                  <v-text-field label="Name" v-model="newProduct.name" :rules="rules.name" />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-form>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn color="error" text @click="close">Cancel</v-btn>
                          <v-spacer />
                          <v-btn color="primary" text @click="save" :disabled="errors">Save</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:items="props">
                  <td>{{ props.item.name }}</td>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
  </v-container>
</template>

<script>
import alert from '@/mixins/alert';
import formValidation, { name, id } from '@/mixins/validation';

export default {
  mixins: [alert, formValidation, name, id],

  data() {
    return {
      products: [],
      loading: false,
      headers: [{
        text: 'Name',
        align: 'left',
        sortable: false,
        value: 'name'
      }],
      showCreate: false,
      newProduct: {
        id: '',
        name: ''
      }
    };
  },

  async mounted() {
    this.loadProducts();
  },

  methods: {
    async loadProducts() {
      try {
        this.loading = true;
        const { data } = await this.$services.product.all();
        this.products = data.products;
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loading = false;
      }
    },

    goToProduct(user) {
      const { id } = user;
      this.$router.push({ name: 'product', params: { id } });
    },

    async save() {
      if (!this.errors) {
        try {
          this.pending = true;
          await this.$services.product.create(this.newProduct);
          this.alertSuccess(`${this.newProduct.name} created`);
        } catch (error) {
          this.alertError(error);
        } finally {
          this.pending = false;
          this.close();
        }
      }
    },

    close() {
      this.showCreate = false;
      this.newProduct = {
        id: '',
        name: ''
      };
    }
  }
};
</script>
