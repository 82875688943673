export default {
  data() {
    return {
      formValid: false
    };
  },

  computed: {
    errors() {
      return !this.formValid;
    }
  }
};

export const id = {
  data() {
    return {
      rules: {
        id: [
          v => !!v || 'id is required',
          v => /^[a-zA-Z0-9]*$/.test(v) || 'ID must not contain spaces'
        ]
      }
    };
  }
};

export const name = {
  data() {
    return {
      rules: {
        name: [
          v => !!v || 'Name is required'
        ]
      }
    };
  }
};

export const email = {
  data() {
    return {
      rules: {
        email: [
          v => !!v || 'Email is required',
          v => /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'Email must be valid'
        ]
      }
    };
  }
};

export const password = {
  data() {
    return {
      rules: {
        password: [
          v => !!v || 'Password is required',
          v => (v && v.length >= 8 && v.length <= 30) || 'Password must be more than 8 characters, but less than 30',
          v => /\d/.test(v) || 'Password must contain numbers',
          v => /[^a-zA-Z0-9]/.test(v) || 'Password must special characters',
          v => /[a-z]/.test(v) || 'Password must contain lowercase letters',
          v => /[A-Z]/.test(v) || 'Password must contain uppercase letters'
        ]
      }
    };
  }
};

export const product = {
  data() {
    return {
      rules: {
        product: [
          v => !!v || 'Product is required'
        ]
      }
    };
  }
};

export const plan = {
  data() {
    return {
      rules: {
        plan: [
          v => !!v || 'Plan is required'
        ]
      }
    };
  }
};

export const organization = {
  data() {
    return {
      rules: {
        organization: [
          v => !!v || 'Organization is required'
        ]
      }
    };
  }
};

export const user = {
  data() {
    return {
      rules: {
        user: [
          v => !!v || 'User is required'
        ]
      }
    };
  }
};

export const role = {
  data() {
    return {
      rules: {
        role: [
          v => !!v || 'Role is required'
        ]
      }
    };
  }
};
