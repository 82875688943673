import Vue from 'vue';
import get from 'lodash/get';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import vuetify from '@/plugins/vuetify';
import { app, auth0Config, sentry } from '@/config';
import Auth from '@/plugins/auth';
import router from '@/router';
import store from '@/store';
import services from '@/services';
import App from '@/App.vue';
import DlaAdditionalOptions from '@/components/DlaAdditionalOptions';
import TlaAdditionalOptions from '@/components/TlaAdditionalOptions';
import AdAdditionalOptions from '@/components/AdAdditionalOptions';
import NwtmAdditionalOptions from '@/components/NwtmAdditionalOptions';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Auth, auth0Config);
Vue.use(services);
Vue.component('dla-additional-options', DlaAdditionalOptions);
Vue.component('tla-additional-options', TlaAdditionalOptions);
Vue.component('ad-additional-options', AdAdditionalOptions);
Vue.component('nwtm-additional-options', NwtmAdditionalOptions);

const { NODE_ENV: env } = process.env;
Vue.config.debug = env !== 'production';
Vue.config.devtools = env !== 'production';
Vue.config.productionTip = env !== 'production';

if (sentry.dsn) {
  Sentry.init({
    debug: env !== 'production',
    environment: app.environment,
    dsn: sentry.dsn,
    release: `${app.name}@${app.version}`,
    beforeBreadcrumb(breadcrumb, hint) {
      // if a button was clicked, get the id of the button
      if (breadcrumb.category === 'ui.click' && hint) {
        const path = get(hint, 'event.path', []);
        if (path.length) {
          for (let index = 0; index < path.length; index++) {
            const { id } = path[index];
            if (id) {
              breadcrumb.message = `#${id}`;
              break;
            }
          }
        }
      }
      return breadcrumb;
    },
    integrations: [new Integrations.Vue({
      Vue,
      attachProps: true
    })],
    ignoreUrls: [
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extensions:\/\//i
    ]
  });
}

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
